<template>
  <div class="loading-overlay">
    <div class="loading-container">
      <img
        src="/static/unstruct-logo-new.png"
        alt="Loading..."
        class="loading-image"
      />
      <div class="loading-ring"></div>
      <div class="loading-pulse"></div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: 'Loading',
  computed: {
    ...mapFields("app", ["loading"]),
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(30, 34, 39, 0.95);
  backdrop-filter: blur(5px);
}

.loading-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  animation: float 3s ease-in-out infinite;
  z-index: 2;
  filter: drop-shadow(0 4px 12px rgba(0, 75, 251, 0.2));
}

.loading-ring {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top: 3px solid #004bfb;
  border-right: 3px solid #004bfb;
  animation: rotate 2s linear infinite;
}

.loading-pulse {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 75, 251, 0.1) 0%, transparent 70%);
  animation: pulse 2s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
}

/* Optional: Add entrance animation */
.loading-overlay {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Optional: Add hover effect */
.loading-container:hover .loading-ring {
  animation-duration: 1s;
}

@media (prefers-reduced-motion: reduce) {
  .loading-image,
  .loading-ring,
  .loading-pulse {
    animation: none;
  }
}
</style>
