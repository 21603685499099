// store/modules/curatedContent.js
import API from "@/api"

const resource = '/curated_content';

export default {
    namespaced: true,

    state: {
        trendingContent: [],
        selectedPlatform: 'linkedin',
        isLoading: false,
        error: null
    },

    mutations: {
        SET_TRENDING_CONTENT(state, content) {
            state.trendingContent = content;
        },
        SET_SELECTED_PLATFORM(state, platform) {
            state.selectedPlatform = platform;
        },
        SET_LOADING(state, status) {
            state.isLoading = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        }
    },

    actions: {
        async fetchTrendingContent({ commit, state }) {
            commit('SET_LOADING', true);
            try {
                const token = localStorage.getItem("token");
                const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");
                let response;

                const params = {
                    platform: state.selectedPlatform
                };

                if (token) {
                    response = await API.get(`${resource}/trending`, { params });
                } else if (unstruct_anonymous_key) {
                    response = await API.get(`${resource}/anonymous/trending/${unstruct_anonymous_key}`, { params });
                } else {
                    throw new Error("No authentication or temporary session found");
                }

                commit('SET_TRENDING_CONTENT', response.data);
            } catch (error) {
                commit('SET_ERROR', error.message);
            } finally {
                commit('SET_LOADING', false);
            }
        },

        setSelectedPlatform({ commit, dispatch }, platform) {
            commit('SET_SELECTED_PLATFORM', platform);
            dispatch('fetchTrendingContent');
        }
    },

    getters: {
        formattedContent: state => {
            return state.trendingContent.map(item => ({
                text: item.text,
                timeAgo: getTimeAgo(item.created_at),
                link: item.link || null
            }));
        }
    }
};

// Helper function to format time
function getTimeAgo(timestamp) {
    const now = new Date();
    const past = new Date(timestamp);
    const diff = now - past;

    const minutes = Math.floor(diff / 60000);
    if (minutes < 60) return `${minutes}m ago`;

    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours}h ago`;

    const days = Math.floor(hours / 24);
    return `${days}d ago`;
}
