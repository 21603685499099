// Object store for the websocket module

import Vue from 'vue';
import { getField, updateField } from "vuex-map-fields"
import UserApi from "@/auth/api";
import router from "@/router";
import auth_store from "@/auth/store";

export default {
    namespaced: true,
    state: {
        activeObject: null,
        activeObjectContent: null,
        objectId: null,
        shouldPrependBAO: false,
        currentUniverse: localStorage.getItem("selectedUniverse") || "default",
        tempMessage: {
            text: '',
            type: 'info'
        },
        user: {
            email: '',
            projects: [],
            organizations: [],
            is_pro: false,
            is_pro_team: false,
            subscription_status: null,
            subscription_end_date: null
        },
        currentGalaxyName: '',
        currentUniverseName: '',
        showTempMessage: false,
        subscriptionLoading: false,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_SUBSCRIPTION_LOADING(state, value) {
            state.subscriptionLoading = value;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TEMP_MESSAGE(state, { text, type }) {
            state.tempMessage = { text, type };
        },
        SET_SHOW_TEMP_MESSAGE(state, show) {
            state.showTempMessage = show;
        },
        SET_ACTIVE_OBJECT(state, { type, id }) {
            state.activeObject = type;
            state.objectId = id;
            state.shouldPrependBAO = true; // Prepend IAO when object is selected, as it is the explicit action.
        },
        SET_ACTIVE_OBJECT_CONTENT(state, content) {
            state.activeObjectContent = content;
        },
        RESET_ACTIVE_OBJECT(state) {
            state.activeObject = null;
            state.objectId = null;
            state.shouldPrependBAO = false;
        },
        RESET_ACTIVE_OBJECT_CONTENT(state) {
            state.activeObjectContent = null;
        },
        SET_SHOULD_PREPEND_BAO(state, shouldPrepend) {
            state.shouldPrependBAO = shouldPrepend;
        },
        SET_CURRENT_UNIVERSE(state, slug) {
            state.currentUniverse = slug;
        },
        SET_CURRENT_GALAXY_NAME(state, name) {
            state.currentGalaxyName = name;
        },
        SET_CURRENT_UNIVERSE_NAME(state, name) {
            state.currentUniverseName = name;
        },
    },
    actions: {
        async upgradePlan({ commit, state, dispatch }, planType) {
            commit('SET_SUBSCRIPTION_LOADING', true);
            try {
                const host = window.location.host;
                const organization = localStorage.getItem('organization');

                let url;
                let headers = {
                    'Content-Type': 'application/json'
                };

                // Determine endpoint based on auth status
                if (auth_store.state.currentUser?.token) {
                    url = `https://${host}/api/v1/${organization}/stripe/${planType === 'pro' ? 'upgrade-to-pro' : 'upgrade-to-team-pro'}`;
                    headers['Authorization'] = `Bearer ${auth_store.state.currentUser.token}`;
                } else {
                    const anonymousKey = localStorage.getItem('unstruct_anonymous_key');
                    if (!anonymousKey) {
                        throw new Error('No authentication method available');
                    }
                    url = `https://${host}/api/v1/${organization}/stripe/anonymous/${planType === 'pro' ? 'upgrade-to-pro' : 'upgrade-to-team-pro'}/${anonymousKey}`;
                }

                const response = await fetch(url, {
                    method: 'POST',
                    headers
                });

                if (!response.ok) {
                    throw new Error('Failed to process subscription request');
                }

                const data = await response.json();

                if (data.checkout_url) {
                    window.location.href = data.checkout_url;
                } else if (data.status === 'success') {
                    dispatch('showTempMessageFn', {
                        message: data.message || `Successfully upgraded to ${planType}.`,
                        type: 'success',
                        timeout: 5000
                    });
                    dispatch('fetchUserInfo');
                } else {
                    throw new Error('Unexpected response from server');
                }
            } catch (error) {
                console.error(`Error processing ${planType} subscription:`, error);
                dispatch('showTempMessageFn', {
                    message: `Failed to process ${planType} subscription. Please try again.`,
                    type: 'error',
                    timeout: 500000
                });
            } finally {
                commit('SET_SUBSCRIPTION_LOADING', false);
            }
        },
        fetchUserInfo({ commit }) {
            return UserApi.getUserInfo()
                .then(response => {
                    commit('SET_USER', response.data);
                })
                .catch(error => {
                    console.error('Error fetching user info:', error);
                });
        },
        showTempMessageFn({ commit }, message, type = 'info', timeout = 5000) {
            // Ensure we have a valid message.
            if (typeof message !== 'string' && typeof message !== 'object') {
                console.error('Invalid message type for showTempMessageFn');
                return;
            }
            let finalMessage, finalType, finalTimeout;

            if (typeof message === 'object') {
                ({ message: finalMessage, type: finalType = 'info', timeout: finalTimeout = 5000 } = message);
            } else {
                finalMessage = message;
                finalType = type;
                finalTimeout = timeout;
            }

            commit('SET_TEMP_MESSAGE', { text: finalMessage, type: finalType });
            commit('SET_SHOW_TEMP_MESSAGE', true);
            setTimeout(() => {
                commit('SET_SHOW_TEMP_MESSAGE', false);
            }, finalTimeout);
        },
        switchUniverse({ commit }, slug) {
            return new Promise((resolve, reject) => {
                commit('SET_CURRENT_UNIVERSE', slug);
                localStorage.setItem("selectedUniverse", slug);
                // You might want to perform other actions here, like updating the URL
                resolve();
            });
        },
        updateCurrentGalaxy({ commit }, galaxyName) {
            commit('SET_CURRENT_GALAXY_NAME', galaxyName);
        },
        updateCurrentUniverse({ commit }, universeName) {
            commit('SET_CURRENT_UNIVERSE_NAME', universeName);
        },
        setActiveObject({ commit, dispatch, state }, { type, id }) {
            // Don't set active object if it is ECCSProject and the incoming is of diff type.
            if (type === 'ECCSProject' && !state.activeObject) {
                commit('SET_ACTIVE_OBJECT', { type, id });
            } else if (type !== 'ECCSProject' && state.activeObject === 'ECCSProject') {
                // Do nothing.
            } else {
                commit('SET_ACTIVE_OBJECT', { type, id });
            }
            dispatch('fetchObjectContent', { type, id });
            // go to 'chat' route. TODO. Open in new tab.
            const current_route = router.currentRoute.name;
            if (current_route !== 'chat' && current_route !== 'BasicChat') {
                router.push({ name: 'chat' });
            }
        },
        fetchObjectContent({ commit, rootState }, { type, id }) {
            const socket = rootState.socket; // Get the socket from the root state
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    type: 'fetch_object_content',
                    object_type: type,
                    object_id: id
                }));
            } else {
                console.error('WebSocket is not open');
            }
        },
        resetActiveObject({ commit }) {
            commit('RESET_ACTIVE_OBJECT');
        },
        resetActiveObjectContent({ commit }) {
            commit('RESET_ACTIVE_OBJECT_CONTENT');
        },
        toggleBAOPrepend({ commit, state }) {
            commit('SET_SHOULD_PREPEND_BAO', !state.shouldPrependBAO);
        },
        resetBAOState({ commit, dispatch }) {
            commit('SET_ACTIVE_OBJECT', { type: null, id: null });
            commit('SET_SHOULD_PREPEND_BAO', false);
            dispatch('resetActiveObjectContent');
            dispatch('resetActiveObject');
        },
    }
};
